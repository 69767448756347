import PropTypes from 'prop-types'
import React from 'react'
import Mailchimp from '../components/mailchimp'
import styled from 'styled-components'
import { FaLinkedinIn, FaGithub, FaTwitter, FaMailchimp } from 'react-icons/fa'

const Footer = () => (
  <FooterWrapper>
    <nav className="nav-wrapper transparent">
      <div className="container">
        &copy; All rights reserved.
        <ul className="right">
          <li>
            <a href="https://twitter.com/divinerdweb">
              <FaTwitter />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/quamruz-zaman-062a6151/">
              <FaLinkedinIn />
            </a>
          </li>
          <li>
            <a href="https://github.com/zamanq">
              <FaGithub />
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <div className="fixed-action-btn">
      <a className="modal-trigger btn-floating btn-large white" href="#modal2">
        <FaMailchimp size={38} style={{ color: 'purple', marginTop: '8px' }} />
      </a>
    </div>
    <div id="modal2" className="modal bottom-sheet newsletter">
      <div className="modal-content">
        <Mailchimp />
      </div>
      <div className="modal-footer">
        <a href="#!" class="modal-close waves-effect waves-green btn-flat">
          Close
        </a>
      </div>
    </div>
  </FooterWrapper>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: '',
}

export default Footer

const FooterWrapper = styled.footer`
  background: linear-gradient(90deg, rgba(123, 31, 162), rgba(156, 39, 176));
`
