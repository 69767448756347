import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'
import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch,
  SearchBox,
  Hits,
  connectStateResults,
} from 'react-instantsearch-dom'

import Search from './search'
import Image from './image'
import 'materialize-css/dist/css/materialize.css'
import './fonts.css'
if (typeof window !== 'undefined') {
  require('materialize-css/dist/js/materialize.min.js')
}

const searchClient = algoliasearch(
  '4Q3515XSCE',
  '1750a77cc9cf468b0e921b1957dc6b32'
)

const Results = connectStateResults(({ searchState }) =>
  searchState && searchState.query ? <Hits hitComponent={Search} /> : null
)

class Header extends Component {
  componentDidMount() {
    const elem = document.querySelectorAll('.sidenav')
    window.M.Sidenav.init(elem, {})

    const parallax = document.querySelector('.parallax')
    window.M.Parallax.init(parallax, {})

    const modal = document.querySelectorAll('.modal')
    window.M.Modal.init(modal, {})
  }

  render() {
    const { menu } = this.props

    return (
      <HeaderWrapper>
        <nav className="nav-wrapper nav-custom">
          <div className="container">
            <Link to="/" className="brand-logo" key="home">
              ZAMAN.Q
            </Link>
            <Link
              to="#"
              className="sidenav-trigger"
              data-target="mobile-menu"
              key="sidena"
            >
              <i className="material-icons">menu</i>
            </Link>
            <ul className="right hide-on-med-and-down">
              {menu.map(item => (
                <li>
                  <Link
                    key={item.object_slug}
                    to={`/${item.object_slug}`}
                    activeClassName="active-link"
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
              <li>
                <a className="modal-trigger" href="#modal1">
                  <i className="material-icons" style={{ lineHeight: '68px' }}>
                    search
                  </i>
                </a>
              </li>
            </ul>

            <ul className="sidenav side-nav" id="mobile-menu">
              <Link to="/" key="12">
                <Image />
              </Link>

              {menu.map(item => (
                <li>
                  <Link
                    key={item.object_slug}
                    to={`/${item.object_slug}`}
                    activeClassName="active-link"
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
              <li>
                <a className="modal-trigger" href="#modal1">
                  <i className="material-icons" style={{ lineHeight: '68px' }}>
                    search
                  </i>
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div id="modal1" className="modal modal-fixed-footer">
          <div className="modal-content">
            <h4>Search</h4>
            <InstantSearch searchClient={searchClient} indexName="zaman_INDEX">
              <SearchBox />
              <Results />
            </InstantSearch>
          </div>
          <div className="modal-footer">
            <a
              href="#!"
              className="modal-close waves-effect waves-green btn-flat"
            >
              Close
            </a>
          </div>
        </div>
      </HeaderWrapper>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header

const HeaderWrapper = styled.div``
