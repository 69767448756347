import React from 'react'
import { Link } from 'gatsby'

const Search = ({ hit }) => (
  <>
    <div className="col s12 m6 l3 search" key={hit.slug}>
      <div className="card">
        <div className="card-image">
          <Link
            to={hit.slug}
            className="btn-floating halfway-fab waves-effect waves-light purple"
          >
            <i className="material-icons">add</i>
          </Link>
        </div>
        <div className="card-content">
          <span className="card-title">{hit.title}</span>
          <h6>{hit.date}</h6>

          <p
            className="truncate"
            dangerouslySetInnerHTML={{ __html: hit.excerpt }}
          />
        </div>
      </div>
    </div>
  </>
)

export default Search
