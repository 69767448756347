import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import Footer from './footer'
import './layout.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={query}
    render={data => (
      <>
        <Header
          menu={data.wordpressWpApiMenusMenusItems.items}
          siteTitle={data.site.siteMetadata.title}
        />
        {children}
        <Footer
          menu={data.wordpressWpApiMenusMenusItems.items}
          siteTitle={data.site.siteMetadata.title}
        />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
    wordpressWpApiMenusMenusItems(slug: { eq: "menu-1" }) {
      items {
        title
        object_slug
      }
    }
  }
`
