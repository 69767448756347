import React, { Component } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default class Mailchimp extends Component {
  state = {
    name: null,
    email: null,
  }

  _handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  _handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(this.state.email, {
      FNAME: this.state.name,
    })
      .then(({ msg, result }) => {
        if (result !== 'success') {
          throw msg
        }
        alert(msg)
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  render() {
    return (
      <div className="container">
        <div className="row center">
          <h5 style={{ margin: '10px 0' }}>Subscribe to the Newsletter</h5>
          <form onSubmit={this._handleSubmit}>
            <div className="row">
              <div className="input-field col s12 m4 l4">
                <input
                  id="name"
                  type="text"
                  onChange={this._handleChange}
                  name="name"
                  className="validate"
                  required
                />
                <label for="name">Name</label>
              </div>
              <div className="input-field col s12 m4 l4">
                <input
                  id="email"
                  type="email"
                  onChange={this._handleChange}
                  name="email"
                  className="validate"
                  required
                />
                <label for="email">Email</label>
              </div>

              <div className="input-field col s12 m4 l4">
                <button className="btn btn-large purple" id="submit">
                  Subscribe
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
